@use '../node_modules/@angular/material' as mat;
@use "./styles/style-variables" as var;

@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "../node_modules/material-design-icons-iconfont/dist/material-design-icons.css";
@import "styles/fonts";

@import "./styles/style-reset";

@import "styles/theme";
@import "./styles/typography";

@import "styles/margin";
@import "styles/size";
@import "styles/text";

@import "styles/custom/button";
@import "styles/custom/form-field";
@import "styles/custom/expansion-panel";
@import "styles/custom/stepper";
@import "styles/custom/slider";
@import "styles/custom/tab-bar";

@include mat.core($recrewt-typography);

@mixin custom-components-theme() {
  @include recrewt-button-style();
  @include recrewt-form-field-style();
  @include recrewt-stepper-style();
  @include recrewt-slider-style();
  @include recrewt-tab-bar-style();
}

.recrewt-light-theme {
  @include mat.all-component-themes($theme);
  @include custom-components-theme();
}

.theme-alternate {
  @include mat.all-component-themes($altTheme);
  @include custom-components-theme();
}

.primary-bg {
  background-color: var(--company-primary, var.$primary) !important;
  color: var(--company-on-primary, var.$on-primary) !important;
}

.primary-bg-low {
    background-color: rgba(var.$primary, 0.12) !important;
}

.primary {
  color: var(--company-primary, var.$primary);
}

.primary-medium {
  color: var(--company-primary, var.$primary);
  opacity: 0.74;
}

.accent {
  color: var(--company-accent, var.$accent);
}

.accent-bg {
  background-color: var(--company-accent, var.$accent) !important;
  color: var(--company-on-accent, var.$on-accent) !important;
}

a {
  text-decoration: none;
  color: var(--company-accent, var.$accent);

  &:hover {
    color: var(--company-primary, var.$primary),;
  }
}

.surface {
  background-color: var.$card-background;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.on-primary {
  color: var(--company-on-primary, var.$on-primary);
}

.on-primary-medium {
  color: var(--company-on-primary, var.$on-primary);
  opacity: 0.74;
}

.on-primary-low {
  color: var(--company-on-primary, var.$on-primary);
  opacity: 0.38;
}

.on-surface {
  color: var.$text;
}

.on-surface-medium {
  color: var.$secondary-text;
}

.warn {
  color: var.$warn;
}

.gradient-bg {
  background-image: linear-gradient(to bottom right, var(--company-primary, var.$primary), var(--company-accent, var.$accent));
  color: var(--company-on-primary, var.$on-primary);
}

.gradient-text-color {
  background: linear-gradient(to bottom right, var(--company-primary, var.$primary), var(--company-accent, var.$accent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 #00000000;
  display: block;
}

.gradient-recrewt-text-color {
  background: linear-gradient(to bottom right, var.$dislike_end, var.$dislike_start);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 #00000000;
  display: block;
}


.gradient-recrewt-text-color-hover:hover {
  @extend .gradient-recrewt-text-color;
}

.gradient-text-color-hover:hover {
  @extend .gradient-text-color;
}


.gradient-text-shadow {
  filter: drop-shadow(0px 4px 4px rgba(var.$text, 0.25));
}

.text-shadow {
  text-shadow: 0 4px 4px rgba(var.$text, 0.25);
}

.tooltip {
  background-color: rgba(var.$text, 0.54);
}

.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 100;
}

.show-breaks{
  white-space: pre-line;
}

.capitalize {
  text-transform: uppercase;
}

.ellipsize {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;

    &:active {
        cursor: grabbing;
    }
}

.border-radius {
  border-radius: 8px !important;
}

.border-radius-l {
  border-radius: 16px !important;
}

.round {
  border-radius: 50% !important;
}


.swipe-card-heap[recrewtSwipeable] {
  display: block;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 100%;

  &:nth-child(1) {
    transform: translate3d(0, 0px, 0px);
    transition: transform .3s ease;
    position: relative;
    z-index: 3;
    visibility: visible;
  }

  &:nth-child(2) {
    transform: translate3d(5px, -5px, 0px);
    z-index: 2;
    visibility: visible;
    opacity: 0.9;
  }

  &:nth-child(3) {
    transform: translate3d(10px, -10px, 0px);
    z-index: 1;
    visibility: visible;
    opacity: 0.8;
  }

  .swipe-card-overlay-left {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
  }
  .swipe-card-overlay-right {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
  }
}

.hidden {
    display: none !important;
}

.overflow {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.max-width-50 {
  max-width: 50%;
}

.width-60 {
  width: 60%;
}

.width-80 {
  width: 80%;
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.gradient-button {
  border-radius: 56px !important;
  height: 56px;
  padding-block: 12px;
}

a.gradient-button {
  padding-top: 10px !important;
  @extend .mat-elevation-z2;
}


a.gradient-button,
button.gradient-button:enabled {
  background: var.$gradient !important;

  &.default {
    background: var.$default-gradient !important;
  }
}

.gradient-button-text {
  padding-inline: 24px;
  color: var.$on-primary;
  text-transform: uppercase;
}

.ellipsize-html {
  *:first-child {
    @extend .ellipsize;
  }

  *:not(:first-child) {
    display: none;
  }
}

.block {
  display: block;
}

.tint-white {
  filter: brightness(0) invert(1);
}
