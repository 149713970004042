@use 'sass:list';
@use 'sass:map';
@use "../../node_modules/@material/layout-grid/variables";
@use "../../node_modules/@material/layout-grid/mixins";

.height-match-screen {
  height: 100vh;
}

.height-match-parent {
  height: 100%;
}

.height-wrap-content {
  height: fit-content;
}

.width-match-screen {
  width: 100vw;
}

.width-match-parent {
  width: 100%;
}

.width-wrap-content {
  width: fit-content;
}

.no-overflow {
  overflow: hidden;
  position: relative;
}

.no-overflow-x {
  overflow-x: hidden;
  position: relative;
}

.auto-overflow {
  overflow: auto;
}

.scroll-x {
  overflow-x: scroll;
  height: calc(100% - 36px);
}

.scroll-y {
  overflow-y: scroll;
}
