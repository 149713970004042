@use '../../node_modules/@angular/material' as mat;
@use "theme" as theme;

$accent: mat.get-color-from-palette(theme.$recrewt-accent, main);
$accent-100: mat.get-color-from-palette(theme.$recrewt-accent, 100);
$accent-200: mat.get-color-from-palette(theme.$recrewt-accent, 200);
$accent-300: mat.get-color-from-palette(theme.$recrewt-accent, 300);

$warn: mat.get-color-from-palette(theme.$recrewt-warn);
$warn-100: mat.get-color-from-palette(theme.$recrewt-warn, 100);

$on-accent: mat.get-contrast-color-from-palette(theme.$recrewt-accent, main);
$on-primary:  mat.get-contrast-color-from-palette(theme.$recrewt-primary, main);
$on-primary-medium: rgba($on-primary, 0.87);
$on-primary-low: rgba($on-primary, 0.6);

$super_dislike_start: #de6c74;
$super_dislike_end: #b00020;
$dislike_start: #f79000;
$dislike_end: #E53A7D;
$like_start: #aed581;
$like_end: #558B2F;
$super_like_start: #fae05c;
$super_like_end: #cfb53b;
$undo_start: #FBC77F;
$undo_end: #f79000;

$primary: mat.get-color-from-palette(theme.$recrewt-primary, main);
$primary-50: mat.get-color-from-palette(theme.$recrewt-primary, 50);
$primary-100: mat.get-color-from-palette(theme.$recrewt-primary, 100);
$primary-200: mat.get-color-from-palette(theme.$recrewt-primary, 200);
$primary-300: mat.get-color-from-palette(theme.$recrewt-primary, 300);
$primary-400: mat.get-color-from-palette(theme.$recrewt-primary, 400);

$nudge-amount: 8px;

$text: mat.get-color-from-palette(theme.$recrewt-foreground, text);
$secondary-text: mat.get-color-from-palette(theme.$recrewt-foreground, secondary-text);

$background: mat.get-color-from-palette(theme.$recrewt-background, background);
$card-background: mat.get-color-from-palette(theme.$recrewt-background, card);
$tooltip-background: mat.get-color-from-palette(theme.$recrewt-background, unselected-chip);

$xs: 0;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;

$gradient: theme.$gradient;
$default-gradient: linear-gradient(to bottom right, #ff8f00, #E53A7D);

