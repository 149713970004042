@use "style-variables" as var;

html,
body {
  height: 100%;
  width: 100vw;
}

body {
  margin: 0;
  padding: 0;
}

* {

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    -webkit-appearance: none;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 16px;
    opacity: 0.6;
  }

  &::-webkit-scrollbar-thumb:hover {
    transition: background 1s ease-in-out;
    background: #969696;
  }

  .cdk-virtual-scroll-content-wrapper {
    width: 100% !important;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
