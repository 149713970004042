@use "src/styles/typography" as type;
@use "src/styles/style-variables" as var;

@use '@angular/material' as mat;

@mixin recrewt-stepper-style() {

  mat-horizontal-stepper,
  mat-vertical-stepper {
    background: transparent !important;
  }

  .mat-step-icon,
  .mat-step-icon-selected,
  .mat-step-icon-state-done,
  .mat-step-icon-state-edit {
    .mat-step-icon-content {
      color: var(--company-on-primary, var.$on-primary) !important;
    }
  }

  .static {
    .mat-vertical-stepper-header,
    .mat-horizontal-stepper-header {
      pointer-events: none !important;
    }
  }

  .mat-step-label-selected {
    font-size: mat.font-size(type.$recrewt-typography, subheading-1);
    font-weight: mat.font-weight(type.$recrewt-typography, subheading-1);
  }

  .no-stepper-header {
    .mat-vertical-stepper-header,
    .mat-horizontal-stepper-header-container {
      display: none !important;
    }

    .mat-vertical-content-container {
      margin-left: 0 !important;
    }

    .mat-vertical-content {
      padding: 0 !important;
    }

    .mat-stepper-vertical-line::before {
      border-left-width: 0;
    }
  }

  .fullscreen-stepper {
    .mat-horizontal-stepper-wrapper,
    .mat-horizontal-content-container {
      height: 100%;
    }

    .mat-horizontal-stepper-content {
      .step-content-root {
        height: calc(100vh - 96px);
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }
  }

  .hide-labels {
    .mat-step-label {
      display: none;
    }
  }
}
