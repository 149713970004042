@mixin recrewt-slider-style() {
  .mat-slider-wrapper {
    .mat-slider-thumb-container {
      .mat-slider-thumb-label {
        .mat-slider-thumb-label-text {
          font-size: 20px;
        }
      }
    }
  }
}
