@use "src/styles/style-variables" as var;

@mixin recrewt-tab-bar-style() {
  .tab-label-style {
    margin: 0;
  }

  .mat-tab-label,
  .mat-tab-label-active {
    min-width: 0 !important;
    padding: 4px 24px !important;
    height: 36px !important;
  }

  .mat-tab-label.mat-tab-label-active:not(.mat-tab-disabled),
  .mat-tab-label.mat-tab-label-active.cdk-keyboard-focused:not(.mat-tab-disabled) {
    color: var.$text;
    opacity: 0.87;
  }

  mat-tab {
    padding: var.$nudge-amount * 5;
  }

  .mat-tab-header {
    border-bottom: #ebeef1 1px solid;
  }

  .mat-tab-group.mat-primary .mat-ink-bar {
    height: 2px !important;
    border-radius: 6px 6px 0 0;
  }
}
